// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',

    loading: {
        spinner: 'circles',
        duration: 3000
    },

    // PARAMETROS MEGAPACA
    firebase: {
        apiKey: "AIzaSyADC4_dUi-oB-A44CqngytuGOk-x7ctkQA",
        authDomain: "ianos-app-gt-megapaca-pro.firebaseapp.com",
        projectId: "ianos-app-gt-megapaca-pro",
        storageBucket: "ianos-app-gt-megapaca-pro.appspot.com",
        messagingSenderId: "175492524543",
        appId: "1:175492524543:web:348c1cafd64f5d2000dfca",
        measurementId: "G-0GS0GVJVFJ"
    },
    googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    urlBase: 'https://us-central1-ianos-app-gt-megapaca-pro.cloudfunctions.net',
    apikey: 'a32123a80d9-1166b980f44d3-4ef0-26ac64a5',
    urlBaseWompy: 'https://us-central1-ianos-app-gt-megapaca-pro.cloudfunctions.net/Wompi',

    bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/ianos-app-gt-megapaca-pro.appspot.com/o/assets%2Fimages%2Fcategories%2Ficons%2Fm_1701312957270_1701312957270.e?alt=media&token=eb6e6b4d-5778-42fa-89b7-5e917cca6082',

    // TWILIO CREDENCIALES MEGAPACA
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA9c373d92362e20ec5894b0dcdf7edbb6/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA9c373d92362e20ec5894b0dcdf7edbb6/VerificationCheck`,
        TWILIO_AUTH_TOKEN: 'bb46fff8167de782bb347f9c1e3d3a08',
        TWILIO_ACCOUNT_SID: 'ACdee4f99c35fa1459c95e268e112fe8e9',
        TWILIO_SERVICE_TOKEN: 'VA9c373d92362e20ec5894b0dcdf7edbb6'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    countCharacter(string) {
        let limit = (this.width < 1560) ? 25 : 45;
        if (string.length > limit) {
            string = string.substring(0, limit) + '...';
        }
        return string;
    },

    breadcrumbDynamic(arrayBreadcrumb) {
        let sendBreadcrumbDynamic = [];
        arrayBreadcrumb.forEach(element => {
            if (element != '') {
                let info = {
                    'name': element.replaceAll('-', ' '),
                    'url': element,
                };
                sendBreadcrumbDynamic.push(info);
            }
        });
        return sendBreadcrumbDynamic;
    }
}
    ;

