import { Component, OnInit, Input } from '@angular/core';
import {HttpService} from "../../../services/http.service";

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  public bannerFooter: string = 'assets/images/tools-bg.jpg';
  public txtLeft: string = '';
  middle: any [] = [];
  socialMedia: any [] = [];
  public email: string = '';
  public phone: string = '';
  public color: string = '';
  public version_web: string = '23.12.11'; // Max | 2023-12-11

  constructor(
      private service: HttpService,
  ) {
  }

  ngOnInit(): void {
    this.service.sendGET_SettingsApp('Store').subscribe((res) => {
      this.bannerFooter = res.bannerFooter;
      this.txtLeft     = res.footer.left.content;
      this.color       = res.footer.color != undefined ? res.footer.color : '';
      let contentMiddle = res.footer.middle;
      contentMiddle.forEach( element => {
        if (element.active) this.middle.push(element);
      });
      this.socialMedia = res.social_media;
      this.email        = res.email;
      this.phone        = res.phone;
    });
  }

}
